// @flow

import {rgba} from 'polished';
import Typography from 'typography';

import {themeColors, mediaQueries} from 'theme';

// System font stack
const systemFontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen-Sans',
  'Ubuntu',
  'Cantarell',
  'Helvetica Neue',
  'sans-serif',
];

const fontFamilyPrimary = ['freight-text-pro', systemFontFamily, 'serif'];

export const fontFamilySecondary = `proxima-nova, ${systemFontFamily.join(
  ', ',
)}, sans-serif`;

export const systemFontFallback = systemFontFamily.join(' ');

const typography: any = new Typography({
  title: 'hannoPlaybookDefault',
  includeNormalize: true,
  baseFontSize: '24px',
  baseLineHeight: 1.55,
  boldWeight: 600,
  headerWeight: 500,
  headerFontFamily: fontFamilyPrimary,
  bodyFontFamily: fontFamilyPrimary,
  scaleRatio: 3,
  blockMarginBottom: 0.833,

  /* eslint-disable max-lines-per-function -- complex Typography.js config */
  overrideStyles: ({rhythm}) => ({
    body: {
      wordWrap: 'normal',
      color: themeColors.black100,
      textRendering: 'optimizeLegibility',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
    },
    '.fontSecondary': {
      fontFamily: fontFamilySecondary,
    },
    'h1, h2, h3, h4, h5, h6': {
      width: '100%', // fixes IE 11 wrap issue inside flexbox
    },
    'h2, h3, h4, h5, h6': {
      transform: 'translateX(-2px)',
    },
    h1: {
      fontSize: '4rem',
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: '-1px',
      transform: 'translateX(-3px)',
    },
    h2: {
      fontSize: '2rem',
      lineHeight: 1.2,
      margin: `${rhythm(3 / 2)} 0 ${rhythm(2 / 3)}`,
      letterSpacing: '-1px',
    },
    'h2:first-of-type': {
      marginTop: rhythm(1),
    },
    h3: {
      fontSize: '1.3333333333rem',
      lineHeight: 1.25,
      fontWeight: 500,
      margin: `${rhythm(1)} 0 ${rhythm(1 / 2)}`,
    },
    'h2 + h3': {
      marginTop: 0,
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: 1.25,
      marginBottom: rhythm(1 / 4.5),
    },
    'h4, h5': {
      marginTop: rhythm(1),
      fontWeight: 600,
    },
    'h1 + h4': {
      marginTop: rhythm(1 / 2),
    },
    small: {fontSize: '0.75rem'},
    li: {marginBottom: 0},
    '.blockcaps': {
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    a: {
      color: 'inherit',
      textDecoration: 'underline',
    },
    'a:visited': {
      color: themeColors.textLinkSecondary,
    },
    'a:hover, a:focus': {
      color: themeColors.primary,
      outline: 'none',
      textDecoration: 'underline',
    },
    'a.noDecoration': {
      textDecoration: 'none',
    },
    'p a': {
      transition: 'all 0.1s ease-in-out',
    },
    '::selection': {
      backgroundColor: rgba(themeColors.primary, 0.07),
    },
    'code, pre': {
      fontSize: '100%',
      backgroundColor: rgba(themeColors.primary, 0.1),
      color: themeColors.primary,
      padding: '1px 4px 3px 5px',
      borderRadius: '5px',
    },
    'p code, p pre, li code, li pre': {
      lineHeight: 1,
      margin: '0 2px',
    },
    'li ul, li ol': {
      marginTop: '0.25rem',
    },
    'li p': {
      margin: 0,
    },
    blockquote: {
      fontSize: '1.375rem',
      lineHeight: 1.2,
      fontStyle: 'italic',
      fontWeight: 500,
      margin: `${rhythm(1)} 0`,
      paddingLeft: rhythm(1 / 2),
      borderLeft: `1px solid ${themeColors.textDefault}`,
    },
    [mediaQueries.MAX_MD]: {
      body: {fontSize: '20px'},
      h1: {fontSize: '2.5rem'},
      h2: {fontSize: '1.675rem'},
      h3: {fontSize: '1.25rem'},
      h4: {fontSize: '1.125rem'},
      h5: {fontSize: '0.9rem'},
      p: {marginBottom: rhythm(2 / 3)},
    },
    [mediaQueries.MAX_SM]: {
      body: {fontSize: '18px'},
      h1: {
        fontSize: '2rem',
        marginBottom: rhythm(1 / 2),
        lineHeight: 1.18,
      },
      h2: {
        fontSize: '1.3333333333rem',
        margin: `${rhythm(1 / 3)} 0`,
        fontWeight: 400,
      },
      'h2:first-of-type': {
        marginTop: rhythm(1 / 2),
      },
      h3: {fontSize: '1.1rem'},
      h4: {fontSize: '0.9rem'},
      h5: {fontSize: '0.75rem'},
      'ul, ol': {marginLeft: '0.75rem', marginBottom: rhythm(1 / 2)},
      p: {marginBottom: rhythm(1 / 2)},
      blockquote: {
        fontSize: '1.1rem',
        margin: `${rhythm(1 / 2)} 0`,
      },
    },
  }),
  /* eslint-enable max-lines-per-function -- complex Typography.js config */
});

const {rhythm, scale} = typography;

// eslint-disable-next-line sort-exports/sort-exports -- Otherwise this export will be moved higher up the file and will no longer work
export {rhythm, scale, typography as default};
