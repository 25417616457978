// @flow

const breakpoints = {
  sm: 375,
  md: 768,
  lg: 1025,
  iphoneX: 812,
};

const mq = `@media only screen`;
const landscape = `(orientation:landscape)`;

const minWidth = (breakpoint) => `and (min-width:${breakpoint}px)`;
const maxWidth = (breakpoint) => `and (max-width:${breakpoint - 1}px)`;
const maxHeight = (breakpoint) => `and (max-height:${breakpoint - 1}px)`;

export const mediaQueries = {
  // Maximum media queries
  MAX_SM: `${mq} ${maxWidth(breakpoints.md)}`,
  MAX_MD: `${mq} ${maxWidth(breakpoints.lg)}`,
  // Minimum media queries
  MIN_SM: `${mq} ${minWidth(breakpoints.sm)}`,
  MIN_MD: `${mq} ${minWidth(breakpoints.md)}`,
  MIN_LG: `${mq} ${minWidth(breakpoints.lg)}`,
  // Precise range media queries
  SM_ONLY: `${mq} ${minWidth(breakpoints.sm)} ${maxWidth(breakpoints.md)}`,
  MD_ONLY: `${mq} ${minWidth(breakpoints.md)} ${maxWidth(breakpoints.lg)}`,
  // Mobile landscape orientation
  MOBILE_LANDSCAPE: `${mq} ${maxWidth(breakpoints.lg)} ${maxHeight(
    breakpoints.md,
  )} and ${landscape}`,
  MOBILE_LANDSCAPE_LARGE: `${mq} ${minWidth(breakpoints.iphoneX)} ${maxHeight(
    breakpoints.md,
  )} and ${landscape}`,
  MAX_IPHONEX_LANDSCAPE_PARTIAL: `screen and ${maxWidth(
    breakpoints.iphoneX,
  )} and ${landscape}`,
  MAX_IPHONEX_LANDSCAPE_FULL: `${mq} ${maxWidth(
    breakpoints.iphoneX,
  )} and ${landscape}`,
};
