// @flow

import React, {useState} from 'react';
import Headroom from 'react-headroom';
import styled, {createGlobalStyle, keyframes} from 'styled-components';

import CloseSvg from 'assets/close.svg';
import MenuSvg from 'assets/menu.svg';
import {mediaQueries, themeColors} from 'theme';
import type {PathType, PageMetaType, ChildrenType} from 'types';
import {
  fontFamilySecondary,
  rhythm,
  systemFontFallback,
} from 'utils/typography';

import {version} from '../../package.json';
import strings from '../config/strings.json';

import Link from './Link';
import Logo from './Logo';
import SiteMetadata from './SiteMetadata';

const contentEntrance = keyframes`
  0% { opacity: 0; transform: translate3d(0, 2px, 0); }
  33% { opacity: 0; transform: translate3d(0, 2px, 0); }
  100% { opacity: 1; transform: translate3d(0, 0, 0); }
`;

// See https://www.gatsbyjs.com/docs/how-to/styling/styled-components/#creating-global-styles
const GlobalStyle = createGlobalStyle`
  html {
    overscroll-behavior: none;
  }

  body {
    padding-top: constant(safe-area-inset-top, 0);
    padding-top: env(safe-area-inset-top, 0);

    background: ${themeColors.offWhite};
    opacity: 0;

    &:not(.wf-proxima-nova--loaded),
    &:not(.wf-freight-text-pro--loaded) {
      font-family: ${systemFontFallback};
    }

    &.wf-proxima-nova--loaded,
    &.wf-freight-text-pro--loaded {
      opacity: 1;
    }
  }

  // Grey out inactive sidebar links
  .navItem:not(.current):not(:focus) {
    color: ${themeColors.black40};

    &:before {
      background: ${themeColors.black40};
    }

    &:hover,
    &:focus {
      color: ${themeColors.textLinkSecondary};

      &:before {
        background: ${themeColors.textLinkSecondary};
      }
    }
  }

  // Highlight all links when sidebar is hovered
  // .sidebarNav:hover .navItem {
    // opacity: 1;
  // }

  .gatsby-resp-image-wrapper {
    margin: ${rhythm(1)} 0 !important;
    ${mediaQueries.MIN_MD} {
      margin: ${rhythm(9 / 5)} 0 !important;
    }

    img {
      transform: translateX(-7px);
    }
  }

  ${mediaQueries.MIN_LG} {
    .headroom-wrapper {
      display: none;
    }
  }

  .headroom--unfixed {
    position: fixed;
    transform: translateY(0);
    opacity: 1;
  }
  .headroom--unpinned {
    transform: translateY(-200%);
    opacity: 0;
  }
  .headroom--pinned {
    transform: translateY(0);
    opacity: 1;
  }
  .headroom {
    width: 100vw;
    height: 2.5rem;
    display: flex;
    align-items: center;
    background: transparent;

    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;

    transition: all 0.35s cubic-bezier(0.42, 0, 0.34, 1) !important;
  }

  .footnote-ref {
    text-decoration: none;
    font-weight: 600;
  }

  sup {
    margin: 2px;
  }

  .footnotes {
    margin-top: ${rhythm(1)};
    ${mediaQueries.MIN_MD} {
      margin-top: ${rhythm(2)};
    }
    &:before {
      content: 'Footnotes';
      text-transform: uppercase;
      display: block;
      font-size: 14px;
      font-weight: 500;
      font-family: ${fontFamilySecondary};
      margin-bottom: ${rhythm(2 / 3)};
      letter-spacing: 1px;
    }
    hr {
      display: none;
    }
    li {
      margin-bottom: ${rhythm(1 / 3)};
      transform: translateX(-5px);
      padding-left: 5px;
      font-size: 0.75rem;
      line-height: 1.55;
    }
    p {
      display: inline;
    }
    .footnote-backref {
      text-decoration: none;
    }
    a {
      transition: all 0.1s ease-in-out;
    }
  }
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  background: #fff;

  &:not(.hasSidebar) {
    background: ${themeColors.offWhite};
    h4 {
      font-weight: 400;
    }
  }

  &.hasSidebar {
    min-height: 100vh;

    ${mediaQueries.MAX_MD} {
      position: relative;
      padding-top: 2.5rem;
    }
  }

  .headroom {
    background: ${themeColors.offWhite};

    ${(props) => props.isMenuOpen && 'transform: none; opacity: 1;'};

    display: flex;
    justify-content: space-between;
    align-items: center;
    ${mediaQueries.MIN_LG} {
      display: none;
    }

    a {
      margin: 0.25rem 1rem 0 0;

      svg {
        margin: 0;
        width: 32px;
        height: 32px;
      }
    }
  }

  .menuButton {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: ${themeColors.textSubtle};
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;

    &:focus {
      outline: none;
    }

    span {
      margin: 0 0 0 0.5rem;
      padding: 0;
      line-height: 24px;
    }

    span,
    svg:nth-child(1) {
      opacity: ${(props) => (props.isMenuOpen ? 0 : 1)};
    }

    svg:nth-child(2) {
      position: ${(props) => (props.isMenuOpen ? 'fixed' : 'absolute')};
      top: -1px;
      opacity: ${(props) => (props.isMenuOpen ? 1 : 0)};
    }

    span,
    svg {
      transition: opacity 0.15s 0.15s cubic-bezier(0.42, 0, 0.34, 1);
    }

    transform: ${(props) =>
      props.isMenuOpen ? 'translateX(0.875rem)' : 'translateX(0.75rem)'};
    transition: transform 0.15s 0.15s cubic-bezier(0.42, 0, 0.34, 1);
  }
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${themeColors.backgroundSidebar};

  ${mediaQueries.MAX_MD} {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;

    transform: ${(props) =>
      props.isMenuOpen ? 'translateX(0)' : `translateX(-100vw)`};
    transition: transform 0.2s 0.1s cubic-bezier(0.42, 0, 0.34, 1);
  }

  ${mediaQueries.MIN_LG} {
    min-width: 360px;
    max-width: 380px;
  }
`;

const SidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.5rem 1.5rem 1.5rem;

  ${mediaQueries.MIN_LG} {
    position: sticky;
    top: 0;
    padding-top: ${rhythm(3 / 2)};
    padding-left: ${rhythm(3 / 2)};
    padding-bottom: ${rhythm(3 / 2)};
    height: 100vh;
    justify-content: space-between;
    flex-direction: column;
    overflow-y: scroll;
  }

  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${themeColors.black20};
  }
`;

const LogoWrapper = styled.div`
  width: auto;
  margin-top: ${rhythm(2)};
  margin-left: -16px;

  a {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 1.25;
    color: ${themeColors.textLinkSecondary};
    text-decoration: none;
    transition: all 0.1s ease-in-out;

    ${mediaQueries.MD_ONLY} {
      align-items: flex-start;
    }

    &:hover,
    &:focus {
      color: ${themeColors.primary};
    }

    svg {
      margin-right: 0.5rem;
    }
  }

  .versionNumber {
    margin: 4px 0 0;
    font-size: 14px;
    font-weight: 400;
    color: ${themeColors.textSubtle};
  }
`;

const MainContent = styled.div`
  overflow: hidden;

  .gridContainer:not(.hasSidebar) & {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    width: 100vw;
    min-height: 100vh;

    ${mediaQueries.MIN_MD} {
      align-items: center;
    }
  }

  .gridContainer.hasSidebar & {
    min-width: auto;

    padding: ${rhythm(1 / 2)};
    margin-bottom: constant(safe-area-inset-bottom, 0);
    margin-bottom: env(safe-area-inset-bottom, 0);

    ${mediaQueries.MIN_MD} {
      max-width: 830px;
      margin: 0 auto;
      padding: ${rhythm(3 / 2)} ${rhythm(1)} ${rhythm(1)};
    }

    ${mediaQueries.MIN_LG} {
      margin: 5vw 5vw ${rhythm(7 / 4)};
      padding: 0;
    }
  }

  will-change: transform;
  animation-name: ${contentEntrance};
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`;

type Props = {
  path: PathType,
  children: ChildrenType,
  pageMeta?: PageMetaType,
  sidebar?: React.Component<*, *> | 'div',
};

const Layout = (props: Props): React$Element<React$FragmentType> => {
  const {path, children, pageMeta, sidebar} = props;
  const {versionTitle} = strings.pages['book'];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      <SiteMetadata path={path} pageMeta={pageMeta} />
      <GridContainer
        isMenuOpen={isMenuOpen}
        className={['gridContainer', sidebar && 'hasSidebar'].join(' ')}>
        {sidebar && (
          <>
            <Headroom disableInlineStyles>
              <button
                className="menuButton"
                type="submit"
                aria-label="Menu Button"
                data-testid="menuButton"
                onClick={handleButtonClick}>
                <MenuSvg /> <CloseSvg />
              </button>
              {/* <Link to="/" ariaLabel="Open menu" aria-controls="menu">
                <Logo />
              </Link> */}
            </Headroom>
            <Sidebar
              onClick={mediaQueries.MIN_LG ? undefined : handleButtonClick}
              className="sidebarNav"
              id="menu"
              isMenuOpen={isMenuOpen}>
              <SidebarContentWrapper>
                {sidebar}
                <LogoWrapper>
                  <Link
                    to="/"
                    ariaLabel="Go to Homepage"
                    data-testid="sidebarHomeLink">
                    <Logo aria-hidden="true" />{' '}
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      {strings.pages.home.title}
                      <small className="versionNumber fontSecondary">
                        <span>{`${versionTitle} ${version}`}</span>
                      </small>
                    </div>
                  </Link>
                </LogoWrapper>
              </SidebarContentWrapper>
            </Sidebar>
          </>
        )}
        <MainContent>{children}</MainContent>
      </GridContainer>
    </React.Fragment>
  );
};

export default Layout;
