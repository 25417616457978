// @flow

import {useStaticQuery, graphql} from 'gatsby';

import type {SiteMetadata} from 'types';

// By parsing like this, we make it significantly easier to understand the context of data when consuming it inside the layout components
// You can also add additional GraphQL queries here if you intend to retrieve part of your site metadata from another source (e.g. DatoCMS or Contentful)

export const useSiteMetadata = (): SiteMetadata => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
            siteDescription
            siteUrl
            sitePublisher
          }
        }
      }
    `,
  );

  return site.siteMetadata;
};
