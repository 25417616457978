// @flow

const paletteDefault = {
  primary: '#ea4d47',
  primaryDark: '#1354a0',
  black100: '#303030',
  black80: '#595959',
  black60: '#767676',
  black40: '#acacac',
  black20: '#d6d6d6',
  offWhite: '#fbfbfb',
};

export const themeColors = {
  ...paletteDefault,
  // Text elements
  textLinkSecondary: paletteDefault.black100,
  textDefault: paletteDefault.black80,
  textSubtle: paletteDefault.black60,
  // Backgrounds
  backgroundSidebar: paletteDefault.offWhite,
};
