// @flow

import * as React from 'react';
type Props = {
  title: string,
  subtitle?: string,
};

const Header = (props: Props): React$Element<React$FragmentType> => {
  const {title, subtitle} = props;
  return (
    <React.Fragment>
      <h1 data-testid="page-title">{title}</h1>
      {subtitle && (
        <h3 data-testid="page-subtitle" style={{marginTop: '0.5rem'}}>
          {subtitle}
        </h3>
      )}
    </React.Fragment>
  );
};

export default Header;
