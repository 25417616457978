// @flow
import React from 'react';
import styled from 'styled-components';

import LogoSvg from 'assets/logo.svg';
import {themeColors} from 'theme';

// Wide color gamut (p3) is currently only supported in Safari.
// We make use of it to display a punchier red color of the logo.
// See it in action on mobile Safari (by default) or on a desktop Safari,
// provided that OS-wide color space is set to Display P3.
// Otherwise, we fall back to our default sRGB color

// $FlowFixMe[incompatible-call] - Look into flow-typing styled components
const StyledLogo = styled(LogoSvg)`
  .logoRect {
    fill: ${themeColors.primary};

    @media (color-gamut: p3) {
      fill: color(display-p3 0.91 0.28 0.26);
    }
  }
`;

const Logo = (): React$Element<any> => <StyledLogo />;

export default Logo;
