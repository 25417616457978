// @flow
import slugify from 'slugify';

// $FlowFixMe -- We disable Flow in this file because it needs to be consumed by gatsby-node.esm.js, which does not support Flow. Should be resolved later on.
export const getPageUrl = (path, slug) => {
  // Index simply refers to the homepage, so can be remapped
  if (path === 'index' || slug === 'index') {
    return '/';
  }

  // If there is a slug, we will use this rather than attempting to read from the file name
  if (slug) {
    return slashifyUrl(slug);
  }

  // Slugifies complex paths and file names, removing Markdown extensions
  const segments = path.split('/');
  const slugifiedString = slugify(segments.pop());
  const joinedString = `${segments.join('/')}/${slugifiedString}`;

  let newString = joinedString;

  // Now perform additional modifications
  newString = newString.endsWith('/index.md')
    ? newString.slice(0, -9)
    : newString; // Remove /index.md from the end of the string if it exists
  newString = newString.replace(/\.[^/.]+$/, ''); // Remove any text following a period (i.e. file extension)
  newString = newString.toLowerCase(); // Lowercase everything
  newString = newString.replace(/[()']+/g, ''); // Remove rounded brackets

  newString = slashifyUrl(newString); // Add slashes at the beginning and end, if none exist

  return newString;
};

// $FlowFixMe -- We disable Flow in this file because it needs to be consumed by gatsby-node.esm.js, which does not support Flow. Should be resolved later on.
export const isCurrentNav = (targetString, currentPath, requireExactMatch) => {
  // The "targetString" is the desired first part of the string to match. E.g. "/journal/"
  // The "currentPath" is the current path. E.g. "/journal/some-subdomain-here" or "/contact/"

  const targetCleaned = slashifyUrl(targetString);
  const currentCleaned = slashifyUrl(currentPath);

  if (targetCleaned === '/') {
    if (currentCleaned === targetCleaned) {
      return true; // On the homepage, we only want the "/" link to be marked as active
    }
  } else if (requireExactMatch && currentCleaned === targetCleaned) {
    return true; // If we're on a subpage, we can apply our more specific logic, and we know that this should not match the homepage
  } else if (
    !requireExactMatch &&
    currentCleaned &&
    currentCleaned.startsWith(targetCleaned)
  ) {
    return true; // If we're on a subpage, we can apply our more specific logic, and we know that this should not match the homepage
  }
  return false;
};

// $FlowFixMe -- We disable Flow in this file because it needs to be consumed by gatsby-node.esm.js, which does not support Flow. Should be resolved later on.
export const slashifyUrl = (string) => {
  // Adds slashes to the beginning and end of the URL for consistency
  // Adds a trailing slash if one does not exist
  // Adds a preceding slash if one does not exist
  // Else returns /
  let newString = string.replace(/^\/?([^/]+(?:\/[^/]+)*)\/?$/, '/$1/') || '/';

  // In case a full URL (https://something.else/something) was passed and did not have a trailing slash
  newString += newString.endsWith('/') ? '' : '/';

  return newString;
};
