// @flow

import {Link as GatsbyLink} from 'gatsby';
import React from 'react';

import type {ChildrenType} from 'types';

type Props = {|
  children: ChildrenType,
  to: string,
  ariaLabel?: string,
  className: string,
  'data-testid': string,
|};

const Link = (props: Props): React$Element<'a'> => {
  const {children, to, ariaLabel, 'data-testid': dataTestId, className} = props;

  // TODO: This would benefit from some additional tests
  const isFileLink = /\.[0-9a-z]+$/i.test(to); // File links can't be handled by Gatsby link, so we look for them by inspecting for an extension
  const isInternalRelativeLink = /^\/(?!\/)/.test(to); // We assume that any internal link (intended for Gatsby) will start with exactly one slash, and that anything else is external.
  const isInternalQualifiedLink = /https?:\/\/hanno\.co/i.test(to); // A link which doesn't start with a "/", but still contains hanno.co (and is either http or https)

  // Use Gatsby Link for internal links, unless they are to files
  if (isInternalRelativeLink) {
    // File links are internal, but we can't route to them with Gatsby Link, so they need to be hrefs
    if (isFileLink) {
      return (
        <a
          href={to}
          target="_blank"
          rel="noreferrer"
          aria-label={ariaLabel || null}
          className={className}
          data-testid={dataTestId}>
          {children}
        </a>
      );
    }

    // If the internal link doesn't point to a file, it must be a valid internal Gatsby link.
    // We add a trailing slash (if one doesn't exist), which ensures consistency across the site and should play nicely with `gatsby-plugin-force-trailing-slashes` plugin and works nicely with Netlify's handling of URLs
    let linkTo = to;
    if (linkTo.substr(-1) != '/') linkTo += '/';

    return (
      <GatsbyLink
        to={linkTo}
        aria-label={ariaLabel || null}
        className={className}
        data-testid={dataTestId}>
        {children}
      </GatsbyLink>
    );
  }

  // Internal qualified links don't need to open in a new tab, but we can't handle them via Gatsby Links
  if (isInternalQualifiedLink) {
    return (
      <a
        href={to}
        aria-label={ariaLabel || null}
        className={className}
        data-testid={dataTestId}>
        {children}
      </a>
    );
  }

  // Everything else must be an external link
  return (
    <a
      href={to}
      // target="_blank" // If you wish to open in a new tab
      rel="noopener noreferrer"
      aria-label={ariaLabel || null}
      className={className}
      data-testid={dataTestId}>
      {children}
    </a>
  );
};

export default Link;
